import css from "./css/applicationForm.module.css"
import "./css/applicationForm.css"
import { Form, Button, Alert } from "react-bootstrap"
import CurrencyInput from "react-currency-input-field"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useState, useEffect } from "react"
import { NextStepBtn, SubmitBtnBig, PrevStepBtn } from "./Btn"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import img1 from "../images/applyForm/target1.png"
import img2 from "../images/applyForm/target2.png"
import img3 from "../images/applyForm/target3.png"
import img4 from "../images/applyForm/target4.png"

import moment from "moment"

import { ENV } from "../config"
import { useNavigate } from "react-router-dom"
import { auth } from "../firebase"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { createUser } from "../signature/firebaseConf"
import { ToggleBtnGroup } from "./ToggleBtnGroup"

const ApplicationForm = () => {
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  const [title, setTitle] = useState("")
  const [title2, setTitle2] = useState("")

  /**step1 */
  const [loanTarget, setLoanTarget] = useState("")
  const [loan_selectID, setLoan_selectID] = useState("")
  const [submissionID, setSubmissionID] = useState("")
  const [fullName, setFullName] = useState("")
  const [isFirstSaved, setIsFirstSaved] = useState(false)

  /**end step 1 */

  /**step2 */
  const [phoneNumb, setPhoneNumb] = useState("")
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [idCard, setIdCard] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [birthDate, setBirthDate] = useState("")
  const [sex, setSex] = useState("")
  const [sex_selectID, setSex_selectID] = useState("")
  const [paymentTerms, setPaymentTerms] = useState()
  const [paymentTermsID, setPaymentTerms_ID] = useState("")
  const [employment, setEmployment] = useState()
  const [employmentID, setEmploymentID] = useState("")
  const [sameToPropertyInfo, setSameToPropertyInfo] = useState("")

  /**end step 2 */

  /**step 3 */

  const [amount, setAmount] = useState("")
  const [occupation, setOccupation] = useState("全職")
  const [occ_selectID, setOcc_selectID] = useState("ftEmployee")
  const [payMethod, setPayMethod] = useState("自動轉賬")
  const [pay_selectID, setPay_selectID] = useState("pay1")
  const [income, setIncome] = useState("")
  const [agreeNext, setAgreeNext] = useState("")
  const [industry, setIndustry] = useState("bank")

  const [liveBlock, setLiveBlock] = useState("")
  const [liveUnit, setLiveUnit] = useState("")
  const [liveFloor, setLiveFloor] = useState("")
  const [liveAddress, setLiveAddress] = useState("")
  const [liveRegion, setLiveRegion] = useState("")
  const [liveMethod, setLiveMethod] = useState()
  const [liveMethodID, setLiveMethodID] = useState("")

  const [propertyBlock, setPropertyBlock] = useState("")
  const [propertyUnit, setPropertyUnit] = useState("")
  const [propertyFloor, setPropertyFloor] = useState("")
  const [propertyAddress, setPropertyAddress] = useState("")
  const [propertyRegion, setPropertyRegion] = useState("")
  const [propertyMethod, setPropertyMethod] = useState()
  const [propertyMethodID, setPropertyMethodID] = useState("")
  const [propertyMortageState, setPropertyMortageState] = useState("")

  const [carTypeMode, setCarTypeMode] = useState("")
  const [carTypeModeID, setCarTypeModeID] = useState("")
  const [carBrand, setCarBrand] = useState("")
  const [carColor, setCarColor] = useState("")
  const [carFirstMile, setCarFirstMile] = useState("")
  const [carMile, setCarMile] = useState("")
  const [carLuxInfo, setCarLuxInfo] = useState("")
  const [carBrandFactory, setCarBrandFactory] = useState("")
  const [carModel, setCarModel] = useState("")
  const [carYear, setCarYear] = useState("")
  const [nationality, setNationality] = useState("")

  const [companyName, setCompanyName] = useState("")
  const [companyPhone, setCompanyPhone] = useState()
  const [companyAddress, setCompanyAddress] = useState("")
  const [position, setPosition] = useState("")

  const [carType, setCarType] = useState()
  const [carTypeID, setCarTypeID] = useState("")
  /**step3 */

  /**firebase phone verify */
  const [phoneVerified, setPhoneVerified] = useState(false)

  const countryCode = "+852"
  const [expandForm, setExpandForm] = useState(false)
  const [showsendBtn, setShowsendBtn] = useState(true)
  const [error, setError] = useState("")
  // eslint-disable-next-line no-unused-vars
  const [OTP, setOTP] = useState("")
  const [isSentCode, setIsSentCode] = useState(false)
  const [redo, setRedo] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [showRecaptcha, setShowRecaptcha] = useState(true)
  const restart = () => {
    setError("")
    setExpandForm(false)
    setOTP("")
    setIsSentCode(false)
    setRedo(false)
    setIsSuccess(false)
    setShowsendBtn(true)
    setShowRecaptcha(true)
  }

  const sentCode = () => {
    setError("")
    setExpandForm(true)
    setIsSentCode(true)
  }
  const failsendCode = () => {
    setIsSentCode(false)
    setRedo(true)
    setIsSuccess(false)
    setShowsendBtn(false)
  }

  const verified = () => {
    setError("")
    setPhoneVerified(true)
    setIsSuccess(true)
  }
  const notVerified = () => {
    setPhoneVerified(false)
    setRedo(true)
  }

  const generateRecaptha = () => {
    setShowRecaptcha(true)
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    )
  }

  const requestOTP = (e) => {
    e.preventDefault()
    if (phoneNumb.length >= 8) {
      generateRecaptha()
      let appVerifier = window.recaptchaVerifier
      signInWithPhoneNumber(auth, countryCode + phoneNumb, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult
          sentCode()
        })
        .catch((error) => {
          //error sms not sent
          setShowRecaptcha(false)
          if (error.message.includes("too-many-requests")) {
            setError("抱歉!嘗試次數太多，請稍後再試。")
            failsendCode()
          } else if (error.message.includes("INVALID_CODE")) {
            setError("驗證碼錯誤，請重新輸入")
            failsendCode()
          } else if (error.message.includes("TOO_LONG")) {
            setError("無效電話號碼，請重新輸入")
            failsendCode()
          }
          failsendCode()
        })
    } else {
      return setError("請輸入正確號碼")
    }
  }

  const verifyOTP = (e) => {
    let otp = e.target.value
    setOTP(otp)
    if (otp.length === 6) {
      //verify otp
      let confirmationResult = window.confirmationResult
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          //   const user = result.user
          verified()
        })
        .catch((error) => {
          if (error.message.includes("invalid")) {
            setError("驗證碼錯誤，請重新輸入")
          } else if (error.message.includes("expired")) {
            setError("驗證碼過期")
          }
          notVerified()
        })
    }
  }
  /**end firebase phone verify */

  /**validator*/

  useEffect(() => {
    if (checkRepeatNumber() === false && checkPhoneFormat() === true) {
      setPhoneVerified(true)
    } else {
      setPhoneVerified(false)
    }
  }, [phoneVerified, phoneNumb])

  useEffect(() => {
    const result = checkNormalSequenceNumber("12345")
    console.log("check sequence", result)
  }, [])

  function checkRepeatNumber() {
    let flag = false
    if (
      phoneNumb.includes("11111") ||
      phoneNumb.includes("22222") ||
      phoneNumb.includes("33333") ||
      phoneNumb.includes("44444") ||
      phoneNumb.includes("55555") ||
      phoneNumb.includes("66666") ||
      phoneNumb.includes("77777") ||
      phoneNumb.includes("88888") ||
      phoneNumb.includes("99999") ||
      phoneNumb.includes("00000")
    ) {
      flag = true
      return flag
    } else {
      flag = false
      return flag
    }
  }

  function checkNormalSequenceNumber(numb) {
    let flag = true
    let countSequence = 0

    for (let i = 1; i < numb.length; i++) {
      let diff = numb.substring(i) - numb.substring(i + 1)
      if (diff === 1) {
        countSequence++
      }
      if (countSequence >= 5) {
        flag = false
      }
    }
  }

  function checkPhoneFormat() {
    let reg = /^[2-9][0-9]{7}$/gm
    if (reg.test(phoneNumb) && phoneNumb.length === 8) {
      return true
    } else {
      return false
    }
  }
  /**end validator */

  function handleSaveFirstStep(data) {
    //check if the record is already exist
    if (submissionID) {
      console.log("Record ID:", submissionID)
      return
    }

    const apiURL = "/api/v1/applicationForm"

    fetch(ENV + apiURL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`)
        }
        return res.json() // must return res json to get the response
      })
      .then((response) => {
        if (response.id) {
          console.log("Record ID:", response.id)
          setSubmissionID(response.id)
        } else {
          console.error("No ID found in response")
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
      .finally(() => {})
  }

  function handleNextStep() {
    if (step === 1) {
      if (loanTarget === "") {
        alert("請選擇貸款目的")
        return
      }
      //save the loanTarget to the database and get the id
      let data = {
        fullName: fullName,
        loanTarget: loanTarget,
        phoneNumb: phoneNumb,
        // timestamp: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
      }
      if (!isFirstSaved) handleSaveFirstStep(data)
    }

    setStep(step + 1)
    window.scrollTo({ top: 0, behavior: "instant" })
  }

  function handlePrevStep() {
    setStep(step - 1)
    window.scrollTo({ top: 0, behavior: "instant" })
  }

  useEffect(() => {
    switch (step) {
      case 1:
      case 2:
        setTitle("歡迎使用樂高信貸")
        setTitle2(
          "樂高信貸為閣下提供私人貸款、免TU貸款、業主貸款、清卡數及汽車抵押等多種貸款服務！"
        )
        break

      case 3:
      case 4:
        setTitle("閣下已初步通過審核，離成功貸款又近一步")
        setTitle2(
          "只需提交簡單文件，即可獲知批核結果，輕鬆完成貸款辦理，全程無需現身！"
        )
        break
      case 5:
        setTitle("即刻遞交文件申請貸款資格！")
        setTitle2(
          "只需按照以下步驟即可快速完成網上申請，一經批核，即時過數！助閣下解決資金問題！"
        )
        break

      default:
        break
    }

    if (step === 1) {
      if (loan_selectID !== "") {
        const loan = document.getElementById(loan_selectID).style
        loan.border = "1px solid #512d8a"
      }
      let loanList = ["loan1", "loan2", "loan3", "loan4"]
      if (loan_selectID !== "") {
        loanList = loanList.filter((item) => item !== loan_selectID)
      }
      for (let i = 0; i < loanList.length; i++) {
        let item = document.getElementById(loanList[i]).style
        item.border = "0"
      }
    }
    if (step === 2) {
      if (paymentTermsID !== "") {
        const sex = document.getElementById(paymentTermsID).style
        sex.color = "#4d2988"
        sex.backgroundColor = "#ffffff"
        sex.borderColor = "#4d2988"
      }
      let paymentTermsIDLisst = [
        "payment6",
        "payment12",
        "payment18",
        "payment24",
        "payment30",
        "payment36",
        "payment42",
        "payment48",
        "payment54",
        "payment60",
      ]
      if (paymentTermsID !== "") {
        paymentTermsIDLisst = paymentTermsIDLisst.filter(
          (item) => item !== paymentTermsID
        )
      }
      for (let i = 0; i < paymentTermsIDLisst.length; i++) {
        let item = document.getElementById(paymentTermsIDLisst[i]).style
        item.color = "#7c7c7c66"
        item.backgroundColor = "#dcdcdc4a"
        item.borderColor = "#adadad"
        item.boxShadow = "0 0 3px 1px #84848424"
      }

      if (pay_selectID !== "") {
        const pay = document.getElementById(pay_selectID).style
        pay.color = "#4d2988"
        pay.backgroundColor = "#ffffff"
        pay.borderColor = "#4d2988"
      }
      let payList = ["pay1", "pay2", "pay3"]
      if (pay_selectID !== "") {
        payList = payList.filter((item) => item !== pay_selectID)
      }
      for (let i = 0; i < payList.length; i++) {
        let item = document.getElementById(payList[i]).style
        item.color = "#7c7c7c66"
        item.backgroundColor = "#dcdcdc4a"
        item.borderColor = "#adadad"
        item.boxShadow = "0 0 3px 1px #84848424"
      }
    }
    if (step === 3) {
      if (sex_selectID !== "") {
        const sex = document.getElementById(sex_selectID).style
        sex.color = "#4d2988"
        sex.backgroundColor = "#ffffff"
        sex.borderColor = "#4d2988"
      }
      let sexList = ["sex1", "sex2"]
      if (sex_selectID !== "") {
        sexList = sexList.filter((item) => item !== sex_selectID)
      }
      for (let i = 0; i < sexList.length; i++) {
        let item = document.getElementById(sexList[i]).style
        item.color = "#7c7c7c66"
        item.backgroundColor = "#dcdcdc4a"
        item.borderColor = "#adadad"
        item.boxShadow = "0 0 3px 1px #84848424"
      }
    }

    if (step === 4) {
      if (loan_selectID === "loan3") {
        if (propertyMethodID !== "") {
          const propertyMethod = document.getElementById(propertyMethodID).style
          propertyMethod.color = "#4d2988"
          propertyMethod.backgroundColor = "#ffffff"
          propertyMethod.borderColor = "#4d2988"
        }
        let propertyMethodIDList = ["propertyMethodSelf", "propertyMethodRent"]
        if (propertyMethodID !== "") {
          propertyMethodIDList = propertyMethodIDList.filter(
            (item) => item !== propertyMethodID
          )
        }
        for (let i = 0; i < propertyMethodIDList.length; i++) {
          let item = document.getElementById(propertyMethodIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
      }
    }
    if (step === 5) {
      if (loan_selectID === "loan1" || loan_selectID === "loan2") {
        if (occ_selectID !== "") {
          const occ = document.getElementById(occ_selectID).style
          occ.color = "#4d2988"
          occ.backgroundColor = "#ffffff"
          occ.borderColor = "#4d2988"
        }

        let occList = ["ftEmployee", "ptEmployee", "freelancer"]
        if (occ_selectID !== "") {
          occList = occList.filter((item) => item !== occ_selectID)
        }
        for (let i = 0; i < occList.length; i++) {
          let item = document.getElementById(occList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
        // if (pay_selectID !== "") {
        //   const pay = document.getElementById(pay_selectID).style
        //   pay.color = "#4d2988"
        //   pay.backgroundColor = "#ffffff"
        //   pay.borderColor = "#4d2988"
        // }
        // let payList = ["pay1", "pay2", "pay3"]
        // if (pay_selectID !== "") {
        //   payList = payList.filter((item) => item !== pay_selectID)
        // }
        // for (let i = 0; i < payList.length; i++) {
        //   let item = document.getElementById(payList[i]).style
        //   item.color = "#7c7c7c66"
        //   item.backgroundColor = "#dcdcdc4a"
        //   item.borderColor = "#adadad"
        //   item.boxShadow = "0 0 3px 1px #84848424"
        // }

        if (employmentID !== "") {
          const employmentItem = document.getElementById(employmentID).style
          employmentItem.color = "#4d2988"
          employmentItem.backgroundColor = "#ffffff"
          employmentItem.borderColor = "#4d2988"
        }
        let employmentIDList = [
          "lessYearEmployment",
          "overThreeEmployment",
          "oneToThreeEmployment",
        ]
        if (employmentID !== "") {
          employmentIDList = employmentIDList.filter(
            (item) => item !== employmentID
          )
        }
        for (let i = 0; i < employmentIDList.length; i++) {
          let item = document.getElementById(employmentIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
      } else if (loan_selectID === "loan4") {
        if (carTypeID !== "") {
          const carTypeItem = document.getElementById(carTypeID).style
          carTypeItem.color = "#4d2988"
          carTypeItem.backgroundColor = "#ffffff"
          carTypeItem.borderColor = "#4d2988"
        }
        let carTypeIDList = ["privateCar", "vanCar", "itemCar"]
        if (carTypeID !== "") {
          carTypeIDList = carTypeIDList.filter((item) => item !== carTypeID)
        }
        for (let i = 0; i < carTypeIDList.length; i++) {
          let item = document.getElementById(carTypeIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
        if (carTypeModeID !== "") {
          const carTypeModeItem = document.getElementById(carTypeModeID).style
          carTypeModeItem.color = "#4d2988"
          carTypeModeItem.backgroundColor = "#ffffff"
          carTypeModeItem.borderColor = "#4d2988"
        }
        let carTypeModeIDList = ["firstHand", "secondHand"]
        if (carTypeModeID !== "") {
          carTypeModeIDList = carTypeModeIDList.filter(
            (item) => item !== carTypeModeID
          )
        }
        for (let i = 0; i < carTypeModeIDList.length; i++) {
          let item = document.getElementById(carTypeModeIDList[i]).style
          item.color = "#7c7c7c66"
          item.backgroundColor = "#dcdcdc4a"
          item.borderColor = "#adadad"
          item.boxShadow = "0 0 3px 1px #84848424"
        }
      }
    }

    return () => {}
  }, [
    step,
    loanTarget,
    phoneNumb,
    lastName,
    firstName,
    idCard,
    birthDate,
    sex,
    amount,
    occupation,
    payMethod,
    income,
    paymentTermsID,
    paymentTerms,
    occ_selectID,
    pay_selectID,
    loan_selectID,
    liveMethod,
    liveMethodID,
    propertyMethod,
    propertyMethodID,
    sex_selectID,
    employment,
    employmentID,
    carType,
    carTypeID,
    carTypeModeID,
    carTypeMode,
  ])

  function handleDate(e) {
    setStartDate(e)
    const newDate = new Date(e)
    let day = "/" + (newDate.getUTCDate() + 1)
    let month = "/" + (newDate.getUTCMonth() + 1)
    let year = newDate.getUTCFullYear()
    setBirthDate(year + month + day)
  }

  //update the submission record
  const updateRecord = (updatedData) => {
    let apiURL = `/api/v1/applicationForm/${submissionID}`

    fetch(`${ENV + apiURL}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`)
        }
        return res.json()
      })
      .then((response) => {
        console.log("Record updated successfully:", response)
        return "ok"
      })
      .catch((err) => {
        console.log("Error updating record:", err.message)
      })
  }

  //send Data
  const handleSubmit = async () => {
    let data = {
      fullName: fullName,
      loanTarget: loanTarget,
      phoneNumb: phoneNumb,
      amount: amount,
      paymentTerms: paymentTerms,
      payMethod: payMethod,
      idCard: idCard,
    }

    const userData = {
      idCard: idCard,
      password: phoneNumb,
      displayName: fullName,
      loanNo: idCard,
    }
    // console.log(data)
    // return
    //create user after submit the enquiry form
    await createUser(userData)

    updateRecord(data)

    navigate("/thankyou")
  }

  const step1Pass = fullName && loanTarget && isSuccess
  const step2Pass =
    amount && paymentTerms && payMethod && income && idCard && companyName

  return (
    <div className={`${css.container} apply-form`}>
      <div className="mb-5">
        <h1>{title}</h1>
        <h6>{title2}</h6>
      </div>

      {step === 1 && (
        <div className={css.step1_container}>
          <div className={`mb-5 ${css.step1_buttonContainer}`}>
            <Form.Group className="mb-3">
              <Form.Label>中文全名（同身份證）</Form.Label>
              <Form.Control
                type="Text"
                id="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className={css.textbox}
              />
            </Form.Group>
            {/* <h5>請選擇您的貸款目的</h5> */}
            <h3>請選擇您的貸款目的</h3>
            <div
              className={[
                "d-flex flex-column justify-content-center",
                css.step1_buttonContainer,
              ].join(" ")}
            >
              <div className="d-flex">
                <div
                  id="loan1"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(loan_selectID === "loan1" ? null : "私人貸款")
                    setLoan_selectID(
                      loan_selectID === "loan1" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img1} alt="" />
                  <span className="mt-1">私人貸款</span>
                </div>
                <div
                  id="loan3"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(loan_selectID === "loan3" ? null : "業主貸款")
                    setLoan_selectID(
                      loan_selectID === "loan3" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img3} alt="" />
                  <span className="mt-1">業主貸款</span>
                </div>
              </div>
              <div className="d-flex">
                <div
                  id="loan2"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(loan_selectID === "loan2" ? null : "免TU貸款")
                    setLoan_selectID(
                      loan_selectID === "loan2" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img2} alt="" />
                  <span className="mt-1">免TU貸款</span>
                </div>

                <div
                  id="loan4"
                  className={css.loanTarget}
                  onClick={(e) => {
                    setLoanTarget(
                      loan_selectID === "loan4"
                        ? null
                        : "汽車及貴重物品抵押貸款"
                    )
                    setLoan_selectID(
                      loan_selectID === "loan4" ? "" : e.currentTarget.id
                    )
                  }}
                >
                  <img src={img4} alt="" />
                  <span className="mt-1 text-center">
                    汽車及貴重物品抵押貸款
                  </span>
                </div>
              </div>

              <Form.Group className="mb-3 mt-5">
                <Form.Label>
                  流動電話號碼<span className={css.requiredStar}>*</span>
                </Form.Label>
                <Form.Control
                  type="Text"
                  id="phoneNumb"
                  aria-describedby="phoneHelpBlock"
                  value={phoneNumb}
                  disabled={!loanTarget}
                  onChange={(e) => setPhoneNumb(e.target.value)}
                  style={{ width: "100%" }}
                />

                <Form.Text id="phoneHelpBlock" className="text-muted">
                  請提供有效流動電話號碼，以便接收密碼短訊
                </Form.Text>

                <br />
                {!phoneVerified && phoneNumb.length > 8 && (
                  <Form.Label className="alert-label alertcolor">
                    請輸入正確號碼
                  </Form.Label>
                )}
                <br />

                {expandForm && (
                  <Form.Control
                    type="otp"
                    placeholder="輸入驗證碼"
                    onChange={verifyOTP}
                    disabled={isSuccess}
                    style={{ width: "80%" }}
                  />
                )}
                {!expandForm && showsendBtn && (
                  <Button
                    className="mb-3 purple_btn"
                    onClick={requestOTP}
                    disabled={!phoneVerified}
                  >
                    送出驗證碼
                  </Button>
                )}

                {showRecaptcha && !redo && (
                  <div style={{ zIndex: 999 }} id="recaptcha-container"></div>
                )}

                <div className="d-flex flex-column">
                  {redo && (
                    <Button className="mb-3 purple_btn mt-2" onClick={restart}>
                      重新驗證
                    </Button>
                  )}

                  {isSentCode && !redo && (
                    <p className="msg-success ms-2">驗證碼已寄出</p>
                  )}
                </div>
                {error && (
                  <Alert
                    className="mt-2"
                    variant="danger"
                    style={{ width: "20%" }}
                  >
                    {error}
                  </Alert>
                )}
                {isSuccess && <p className="msg-success">✓ 電話號碼驗證成功</p>}
              </Form.Group>

              <NextStepBtn
                color={"color-orange"}
                disabled={!step1Pass}
                handle={step1Pass ? handleNextStep : null}
              />
            </div>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className={css.step2_container}>
          <h3>你的貸款計劃</h3>
          <Form.Group className="mb-3">
            <Form.Label>
              貸款金額<span className={css.requiredStar}>*</span>
            </Form.Label>
            <br />
            <CurrencyInput
              id="currency"
              name="currency"
              defaultValue={amount}
              decimalsLimit={2}
              prefix={"HK$ "}
              onValueChange={(value, name) => setAmount(value)}
              className="currency-input"
            />
            <br />
            <Form.Text className="text-muted">
              最高可申請額度為HK$ xxx,xxx
            </Form.Text>
          </Form.Group>
          <Form.Label>
            還款期<span className={css.requiredStar}>*</span>
          </Form.Label>
          <br />
          <div className={css.select_group}>
            <Button
              id="payment6"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment6" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment6" ? "" : e.currentTarget.id
                )
              }}
              value="6"
            >
              6
            </Button>
            <Button
              id="payment12"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment12" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment12" ? "" : e.currentTarget.id
                )
              }}
              value="12"
            >
              12
            </Button>
            <Button
              id="payment18"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment18" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment18" ? "" : e.currentTarget.id
                )
              }}
              value="18"
            >
              18
            </Button>
            <Button
              id="payment24"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment24" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment24" ? "" : e.currentTarget.id
                )
              }}
              value="24"
            >
              24
            </Button>
            <Button
              id="payment30"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment30" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment30" ? "" : e.currentTarget.id
                )
              }}
              value="30"
            >
              30
            </Button>
            <Button
              id="payment36"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment36" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment36" ? "" : e.currentTarget.id
                )
              }}
              value="36"
            >
              36
            </Button>
            <Button
              id="payment42"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment42" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment42" ? "" : e.currentTarget.id
                )
              }}
              value="42"
            >
              42
            </Button>
            <Button
              id="payment48"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment48" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment48" ? "" : e.currentTarget.id
                )
              }}
              value="48"
            >
              48
            </Button>
            <Button
              id="payment54"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment54" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment54" ? "" : e.currentTarget.id
                )
              }}
              value="54"
            >
              54
            </Button>
            <Button
              id="payment60"
              onClick={(e) => {
                setPaymentTerms(
                  paymentTermsID === "payment60" ? "" : e.target.value
                )
                setPaymentTerms_ID(
                  paymentTermsID === "payment60" ? "" : e.currentTarget.id
                )
              }}
              value="60"
            >
              60
            </Button>
          </div>
          <div className="mb-3 form_button">
            <Form.Label>
              出糧方式<span className={css.requiredStar}>*</span>
            </Form.Label>
            <br />
            <div className={css.select_group}>
              <Button
                id="pay1"
                onClick={(e) => {
                  setPayMethod(pay_selectID === "pay1" ? "" : e.target.value)
                  setPay_selectID(
                    pay_selectID === "pay1" ? "" : e.currentTarget.id
                  )
                }}
                value="自動轉賬"
              >
                自動轉賬
              </Button>
              <Button
                id="pay2"
                onClick={(e) => {
                  setPayMethod(pay_selectID === "pay2" ? "" : e.target.value)
                  setPay_selectID(
                    pay_selectID === "pay2" ? "" : e.currentTarget.id
                  )
                }}
                value="支票"
              >
                支票
              </Button>
              <Button
                id="pay3"
                onClick={(e) => {
                  setPayMethod(pay_selectID === "pay3" ? "" : e.target.value)
                  setPay_selectID(
                    pay_selectID === "pay3" ? "" : e.currentTarget.id
                  )
                }}
                value="現金"
              >
                現金
              </Button>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>
                每月平均收入<span className={css.requiredStar}>*</span>
              </Form.Label>
              <br />
              <CurrencyInput
                id="income"
                name="income"
                defaultValue={income}
                decimalsLimit={2}
                prefix={"HK$ "}
                onValueChange={(value, name) => setIncome(value)}
                className="currency-input"
              />
            </Form.Group>
            <Form.Group className="mb-3 mt-3">
              <Form.Label>
                {/* 香港身份證號碼<span className={css.requiredStar}>*</span> */}
                香港身份證號碼
              </Form.Label>
              <Form.Control
                type="Text"
                id="idCard"
                value={idCard}
                placeholder="Z 123456 (7)"
                onChange={(e) => setIdCard(e.target.value)}
                className={css.textbox}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>公司名稱</Form.Label>
              <Form.Control
                type="Text"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                className={css.textbox}
              />
            </Form.Group>
          </div>

          <div className="d-flex">
            <PrevStepBtn color={"color-orange"} handle={handlePrevStep} />
            <SubmitBtnBig
              color={"color-orange"}
              disabled={!step2Pass}
              handle={step2Pass ? handleSubmit : null}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ApplicationForm
